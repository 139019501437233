import { IStoreState } from "../types";

export const getAllUsers = (state: IStoreState) =>
    state.users.users;
export const getUsersCurrentPage = (state: IStoreState) =>
  state.users.currentPage;


export const getUsersPerPage = (state: IStoreState) =>
  state.users.perPage;


export const getTotalUserRecords = (state: IStoreState) =>
  state.users.totalRecords;


export const getTotalAddressRecords = (state: IStoreState) =>
  state.users.totalAddressRecords;
export const getTotalSubUserRecords = (state: IStoreState) =>
  state.users.totalSubUserRecords;

export const getUsersLoader = (state: IStoreState) =>
  state.users.isLoading;


export const getCurrentSelectedUser = (state: IStoreState) =>
  state.users.selectedUserData.userData;

export const getCurrentSelectedUserBills = (state: IStoreState) =>
  state.users.selectedUserData.selectedUserBills;

export const getAllUserBills = (state: IStoreState) =>
  state.users.allBills || [];

export const getAllUserTotalBills = (state: IStoreState) =>
  state.users.allBillsCount || 0;

export const getAllUserPerPage = (state: IStoreState) =>
  state.users.allBillsPerPage || 10;

export const getAllUserCurrentPage = (state: IStoreState) =>
  state.users.allBillsCurrentPage || 1;

export const isPlanChooserModalOpen = (state: IStoreState) =>
  state.users.showPlanChooserModal;

export const getAllPlans = (state: IStoreState) => state.users.selectedUserData.allPlans || [];

export const getStripeCardDetails = (state: IStoreState) => state.users.selectedUserData.cardDetails;

export const getLastInvoice = (state: IStoreState) => state.users.selectedUserData.lastInvoice;
export const getCredits = (state: IStoreState) => state.users.selectedUserData.credits;
export const isChangeExpiryModalOpen = (state: IStoreState) => state.users.showChangeExpiryModal;
export const getHostedNumberDetails = (state: IStoreState) =>
  state.users.selectedUserData.hostedNumber || {};

export const getNewAdhocCharge = (state: IStoreState) =>
  state.users.selectedUserData.newAdhocCharge || {};

export const getAdhocCharges = (state: IStoreState) =>
  state.users.selectedUserData.adhocCharges || [];

export const isAdhoChargeModalOpen = (state: IStoreState) =>
  state.users.showAdhocChargeModal;
