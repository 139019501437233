export const APIUrl = {
  login: '/auth/login',
  forgotPassword: '/auth/forgot-password',
  resetPassword: '/auth/reset-password',
  verifyAccount: '/auth/verify',
  profile: '/user/profile',
  getCard: '/admin/users/:id/get-card',
  getAllPlans: '/admin/users/:id/get-plan-details',
  lastInvoice: '/admin/users/:id/last-invoice',
  hostedNumber: '/admin/users/:id/hosted-number',
  changeStatus: '/admin/users/change-status',
  suspendUser: '/admin/users/:id/suspend-account',
  adhocCharges: '/admin/users/:id/adhoc-charge',
  resetBrand: '/admin/users/:id/reset-brand',
  resetCampaign: '/admin/users/:id/reset-campaign',
  fetchAllUsersBills: '/admin/users/bills/subscription',
  createInvoices: '/admin/users/create-invoice',
  executeInvoices: '/admin/users/execute-invoice',
  voidInvoices: '/admin/users/bills/delete',
  changeExpiryDate: '/admin/users/:id/change-expiry-date',
  updatePlan: '/admin/users/:id/update-plan ',
  credits: '/admin/users/:id/credits',
  allUsers: '/admin/users',
  allPhonenumbers: '/admin/users/phone-numbers',
  isvBasicInformation: '/admin/users/:id/isv-basic-information',
  isvCampaignInformation: '/admin/users/:id/isv-campaign-information',
  isvTollfreeInformation: '/admin/users/:id/isv-tollfree-information',
  userAutoLogin: '/admin/user',
};
export const ENV_TYPE = {
  DEV: 'Dev',
  PROD: 'Prod',
};
export const ENV: any = {
  [ENV_TYPE.PROD]: 'https://admin.trutextapp.com/api/v1',
  // [ENV_TYPE.PROD]: 'https://admin.staging.trutextapp.com/api/v1',
  // [ENV_TYPE.PROD]: 'https://admin.prod-copy.trutextapp.com/api/v1',
  // [ENV_TYPE.PROD]: 'http://localhost:8082/api/v1',
};
