import React from 'react';

const DashboardConfig = {
    routes: [
        {
            path: '/bills',
            private: true,
            hasHeader: true,
            hasLayout: true,
            component: React.lazy(() => import('./Bills')),
        },
    ],
};
export default DashboardConfig;
