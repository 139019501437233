import axios from "axios";
import { APIUrl } from "../apiConstants";

export const getBasicInformationAPI = (id: number, phoneId: number) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.get(APIUrl.isvBasicInformation.replace(":id", `${id}`)+ `/${phoneId}`, {
        headers,
        timeout: 10000,
    });
};

export const setBasicInformationAPI = (payload: any) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.post(`${APIUrl.isvBasicInformation}`, payload, {
        headers,
        timeout: 10000,
    });
};

export const getCampaignInformationAPI = (id: number, phoneId: number) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.get(APIUrl.isvCampaignInformation.replace(":id", `${id}`)+ `/${phoneId}`, {
        headers,
        timeout: 10000,
    });
};


export const getTollfreeInformationAPI = (id: number, phoneId: number) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.get(APIUrl.isvTollfreeInformation.replace(":id", `${id}`) + `/${phoneId}`, {
        headers,
        timeout: 10000,
    });
};


export const setCampaignInformationAPI = (payload: any) => {
    const headers = {
        'Content-Type': 'application/json',
    };
    return axios.post(`${APIUrl.isvCampaignInformation}`, payload, {
        headers,
        timeout: 10000,
    });
};
