import React, {
  PropsWithChildren,
  Fragment,
  useMemo,
  useCallback,
} from "react";
import { useAppContext } from "./context/AppContext";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { isSidemenuOpen, sidemenuHoverState } from "./modules/selectors";
import HeaderLayout from "./HeaderLayout";
import classnames from "classnames";
// import TopBar from "./components/TopBar";
import { toggleSideMenu } from "./modules/actions";
// import Sidebar from "./Sidebar";
import PerfectScrollbar from "react-perfect-scrollbar";
import styled from "styled-components";

function Layout(props: PropsWithChildren<{}>) {
  const { routes } = useAppContext();
  const location = useLocation();
  const dispatch = useDispatch();
  const sideMenuState = useSelector(isSidemenuOpen);
  const sideMenuHoverState = useSelector(sidemenuHoverState);
  const showSidebar = useMemo(() => {
    const currentPath = location.pathname;
    let currentRoute = routes.find((x) => x.path === currentPath);
    if (!currentRoute) {
      const portion = currentPath.split("/")[1];
      currentRoute = routes.find((x) => x.path.indexOf(portion) !== -1);
    }
    return !!(currentRoute && currentRoute.hasLayout);
  }, [location, routes]);

  const toggleSideMenuCallback = useCallback(() => {
    dispatch(toggleSideMenu());
  }, [dispatch]);
  // layout-wrapper layout-navbar-full layout-horizontal layout-without-menu
  return showSidebar ? (
    <Fragment>
      <div
        className={`${classnames({
          "layout-menu-collapsed": sideMenuState,
          "layout-menu-hover": sideMenuHoverState,
          "layout-menu-100vh": sideMenuState,
          "layout-menu-fixed": sideMenuState,
          "layout-menu-expanded": sideMenuState,
        })} layout-wrapper layout-content-navbar  `}
      >
        <div className={"layout-container"}>
          {/*<Sidebar />*/}
          <div className="layout-page">
            <HeaderLayout />
            {/*<div className="layout-page">*/}
            <Wrapper className="content-wrapper">
              {/*{hasHeader && <TopBar />}*/}
              {props.children}
            </Wrapper>
            {/*</div>*/}
          </div>
        </div>
        <div
          className="layout-overlay layout-menu-toggle"
          onClick={toggleSideMenuCallback}
        />
      </div>
    </Fragment>
  ) : (
    <Fragment>{props.children}</Fragment>
  );
}
export default Layout;
const Wrapper = styled(PerfectScrollbar)`
  max-height: calc(100vh-62px);
`;
