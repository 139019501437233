import { SagaIterator } from 'redux-saga/index';
import { ISagaAction } from '../types';
import { put, call } from 'redux-saga/effects';
import {
    forgotPasswordApi,
    getUserProfile,
    resetPasswordApi,
    signInApi,
    updateUserProfile, verifyAccountApi
} from '../utils/Apis/user';
import * as actions from '../actions';
import { showAlert } from '../utils/AlertUtils';

export const signIn = function* (action: ISagaAction<any>): SagaIterator {
    try {
        const { email, password } = action.payload;
        const result = yield call(signInApi, { email, password, isAdmin: true });
        yield put(actions.setUserData(result.data.data));
    } catch (e: any) {
        console.log(e);
        if (e !== 'Session expired') {
            const data = {
                title: 'Error!',
                text: e?.response?.data?.error || e?.response?.data?.errors || e?.response?.data?.message,
                icon: 'error',
                confirmButtonText: 'Okay',
            };
            yield call(showAlert, data);
        }
        // alert(e?.response?.data?.errors);
    }
};


export const getProfile = function* (): SagaIterator {
    try {
        const result = yield call(getUserProfile);
        yield put(actions.setUserData(result.data.data));
    } catch (e: any) {
        console.log(e);
        if (e !== 'Session expired') {
            const data = {
                title: 'Error!',
                text: e?.response?.data?.error || e?.response?.data?.errors || e?.response?.data?.message,
                icon: 'error',
                confirmButtonText: 'Okay',
            };
            yield call(showAlert, data);
        }
        // alert(e?.response?.data?.errors);
    }
};


export const updateProfile = function* (
    action: ISagaAction<any>
): SagaIterator {
    try {
        const { firstName, lastName, centerName, website, location } = action.payload;
        const result = yield call(updateUserProfile, { first_name: firstName, last_name: lastName, center_name: centerName, website, location});
        yield put(actions.setUserData(result.data.data));
    } catch (e: any) {
        console.log(e);
        if (e !== 'Session expired') {
            const data = {
                title: 'Error!',
                text: e?.response?.data?.error || e?.response?.data?.errors || e?.response?.data?.message,
                icon: 'error',
                confirmButtonText: 'Okay',
            };
            yield call(showAlert, data);
        }
        // alert(e?.response?.data?.errors);
    }
};


export const resetPasswordSaga = function* (
    action: ISagaAction<any>
): SagaIterator {
    try {
        const { code, newPassword, navigation } = action.payload;
        yield call(resetPasswordApi, { code, new_password: newPassword });
        const data = {
            title: 'Sucess!',
            text: "Your password has successfully updated.",
            icon: 'success',
            confirmButtonText: 'Okay',
        };
        yield call(showAlert, data);
        if (navigation) {
            navigation('/');
        }
    } catch (e: any) {
        console.log(e);
        if (e !== 'Session expired') {
            const data = {
                title: 'Error!',
                text: e?.response?.data?.error || e?.response?.data?.errors || e?.response?.data?.message,
                icon: 'error',
                confirmButtonText: 'Okay',
            };
            yield call(showAlert, data);
        }
    }
};


export const verifyAccountSaga = function* (
    action: ISagaAction<any>
): SagaIterator {
    const { code, navigation } = action.payload;
    try {
        const result = yield call(verifyAccountApi, { code });
        if (result.data.data) {
            yield put(actions.setUserData(result.data.data));
            const data = {
                title: 'Sucess!',
                text: "Your account has successfully activated.",
                icon: 'success',
                confirmButtonText: 'Okay',
            };
            yield call(showAlert, data);
        }
        if (navigation) {
            navigation('/');
        }
    } catch (e: any) {
        console.log(e);
        if (e !== 'Session expired') {
            const data = {
                title: 'Error!',
                text: e?.response?.data?.error || e?.response?.data?.errors || e?.response?.data?.message,
                icon: 'error',
                confirmButtonText: 'Okay',
            };
            yield call(showAlert, data);
            if (navigation) {
                navigation('/');
            }
        }
    }
};


export const forgotPasswordSaga = function* (
    action: ISagaAction<any>
): SagaIterator {
    try {
        const { email } = action.payload;
        yield call(forgotPasswordApi, { email });
        const data = {
            title: 'Sucess!',
            text: "Password reset link has successfully sent to your registered email.",
            icon: 'success',
            confirmButtonText: 'Okay',
        };
        yield call(showAlert, data);
    } catch (e: any) {
        console.log(e);
        if (e !== 'Session expired') {
            const data = {
                title: 'Error!',
                text: e?.response?.data?.error || e?.response?.data?.errors || e?.response?.data?.message,
                icon: 'error',
                confirmButtonText: 'Okay',
            };
            yield call(showAlert, data);
        }
    }
};
