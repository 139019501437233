import { applyMiddleware, combineReducers, compose, createStore } from 'redux';
import createSagaMiddleware from 'redux-saga';
import * as reducers from './reducers';
import rootSaga from './sagas';
import { persistStore, persistReducer } from 'redux-persist';
import localForage from 'localforage';

const sagaMiddleware = createSagaMiddleware();

let composeEnhancers = compose;
const storage = localForage.createInstance({
    name: 'trutext-db',
    storeName: 'trutext-store',
});

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['settings', 'isv']
};

const rootReducer = combineReducers(reducers);

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = createStore(persistedReducer, composeEnhancers(applyMiddleware(sagaMiddleware)));
export const persistor = persistStore(store);
export default store;

sagaMiddleware.run(rootSaga);
