import { createReducer } from 'redux-act';
import {
    authUser,
    setSignupMessage,
    setUserData,
    signOutUser,
    updatePreDraftMessage,
    setRoutes,
    setComponentRoutes, updateMyPhoneNumbers, selectPhoneNumber
} from '../actions';

export interface IMyPhoneNumbers {
    id: number;
    phone_number: string;
    status: string;
    friendly_name: string | null;
    is_tollfree: boolean;
    is_mms_enabled: boolean;
    is_sms_enabled: boolean;
    is_hosted_number:boolean;
    twilio_sid: string;
    unread_count: number;
}

export interface IUser {
    center_name: string | null;
    createdAt: string | null;
    created_by: number | null;
    email: string | null;
    first_name: string | null;
    id: number;
    last_name: string | null;
    is_tollfree_number: boolean | null;
    twilio_account_sid: string | null;
    pre_draft_message: string | null;
    referral_message: string | null;
    is_verified: boolean | null;
    location: string | null;
    modified_by: number | null;
    phone_number: string | null;
    business_phone_number: string | null;
    status: string | null;
    is_suspended: boolean | null;
    token: string | null;
    updatedAt: string | null;
    user_type: string | null;
    website: string | null;
    address?: number | null;
}
export interface ITopBarRoutes {
    navigateTo: string;
    text: string;
}
export interface ITopBarNavigation {
    parentLinkText: string;
    partialLink: string;
    icon: string;
    routes: ITopBarRoutes[]
}

export interface IUserReducer {
    isAuth: boolean;
    user?: IUser;
    myPhoneNumbers: IMyPhoneNumbers[];
    selectedPhoneNumber?: number;
    routes?: ITopBarNavigation[];
    component_routes?: string[];
    pre_draft_message?: string | null;
    registerMessage?: {
        isError: boolean;
        message: string;
        errors?: any;
    };
}

const initialState = {
    isAuth: false,
    routes: [],
    myPhoneNumbers: [],
    component_routes: [],
    pre_draft_message: undefined,
    registerMessage: undefined,
};

export const user = createReducer<IUserReducer>({}, initialState);
user.on(authUser, (state: IUserReducer) => ({
    ...state,
    isAuth: true,
}));
user.on(
    setSignupMessage,
    (state: IUserReducer, payload: { isError: boolean; message: string; errors?: any } | undefined) => ({
        ...state,
        registerMessage: payload,
    }),
);
user.on(setUserData, (state: IUserReducer, payload: IUser) => ({
    ...state,
    isAuth: true,
    user: payload,
}));

user.on(updatePreDraftMessage, (state: IUserReducer, payload: string) => ({
    ...state,
    pre_draft_message: payload
}));

user.on(setRoutes, (state: IUserReducer, payload: ITopBarNavigation[]) => ({
    ...state,
    routes: payload
}));

user.on(setComponentRoutes, (state: IUserReducer, payload: string[]) => ({
    ...state,
    component_routes: payload
}));

user.on(updateMyPhoneNumbers, (state: IUserReducer, payload: IMyPhoneNumbers[]) => ({
    ...state,
    myPhoneNumbers: payload,
    selectedPhoneNumber: payload.length === 1 ? payload[0].id : payload.length >= 1 ? state.selectedPhoneNumber || payload[0].id : state.selectedPhoneNumber
}));

user.on(selectPhoneNumber, (state: IUserReducer, payload: number | undefined) => ({
    ...state,
    selectedPhoneNumber: payload
}));

user.on(signOutUser, () => initialState);
