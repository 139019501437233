import { IStoreState } from "../types";

export const isSidemenuOpen = (state: IStoreState) =>
    state.settings.showSidebar;

export const isExecuteBillingModalOpen = (state: IStoreState) =>
    state.settings.showExecuteBillingModal;

export const sidemenuHoverState = (state: IStoreState) =>
    state.settings.sidebarHoverState;
