import React, { Suspense, useEffect } from "react";
import Router from "./router/Router";
import { noAuthRoutes, authRoutes } from "./router";
import { useAppContext } from "./context/AppContext";
import Layout from "./Layout";
import LogoComponent from "./components/Logo";
import styled from "styled-components";
import { useDispatch, useSelector } from "react-redux";
import { getIsUserAuth } from "./modules/selectors";

const RouterLayout = (props: any) => {
  const dispatch = useDispatch();
  const isAuth = useSelector(getIsUserAuth);
  const { setRoutes } = useAppContext();

  useEffect(() => {
    if (isAuth) {
      setRoutes(authRoutes);
    } else {
      setRoutes(noAuthRoutes);
    }
  }, [isAuth, setRoutes, dispatch]);

  return (
    <React.Fragment>
      <Suspense
        fallback={
          <LoadingWrapper>
            <LogoComponent varient={"transparent"} customWidth={"200px"} />
          </LoadingWrapper>
        }
      >
        <Layout>
          <Router />
        </Layout>
      </Suspense>
    </React.Fragment>
  );
};
export default RouterLayout;
const LoadingWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
`;
