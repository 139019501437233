import { createAction } from "redux-act";

export const changeBasicInformationFieldValue = createAction<{key: string, value: string}>('change basic info value');
export const changeCampaignInformationFieldValue = createAction<{key: string, value: string}>('change campaign info value');
export const changeTollfreeInformationFieldValue = createAction<{key: string, value: string}>('change tollfree info value');

export const updateBasicInformation = createAction<{isSubmited: boolean}>('update basic information');
export const updateCampaignInformation = createAction<{isSubmited: boolean}>('update campaign information');

export const fetchBasicInformation = createAction<{ id: number }>('fetch basic information');
export const fetchCampaignInformation = createAction<{ id: number }>('fetch campaign information');
export const fetchTollfreeInformation = createAction<{ id: number }>('fetch tollfree information');
export const setBasicInformation = createAction<any>('set basic information');
export const setCampaignInformation = createAction<any>('set campaign information');
export const toggleISVInfoLoader = createAction<boolean>('toggle loader');
export const setTollfreeInformation = createAction<any>('set tollfree information');
export const resetBrandInformation = createAction<{ id: number }>('reset brand information');
export const resetCampaignInformation = createAction<{ id: number }>('reset campaing information');
