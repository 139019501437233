import axios from 'axios';
import { APIUrl } from '../apiConstants';

export const signInApi = (payload: any) => {
    const emailheader = {
        'Content-Type': 'application/json',
    };
    return axios.post(APIUrl.login, payload, {
        headers: emailheader,
        timeout: 10000,
    });
};

export const getUserProfile = () => {
    return axios.get(APIUrl.profile, {
        timeout: 10000,
    });
};
export const updateUserProfile = (paylaod: any) => {
    return axios.post(APIUrl.profile, paylaod, {
        timeout: 10000,
    });
};

export const forgotPasswordApi = async (payload: any) => {
    const emailheader = {
        'Content-Type': 'application/json',
    };
    return axios.post(`${APIUrl.forgotPassword}`, payload, {
        headers: emailheader,
        timeout: 10000,
    });
};
export const resetPasswordApi = async (payload: any) => {
    const emailheader = {
        'Content-Type': 'application/json',
    };
    return axios.post(`${APIUrl.resetPassword}`, payload, {
        headers: emailheader,
        timeout: 10000,
    });
};
export const verifyAccountApi = async (payload: any) => {
    const emailheader = {
        'Content-Type': 'application/json',
    };
    return axios.post(`${APIUrl.verifyAccount}`, payload, {
        headers: emailheader,
        timeout: 10000,
    });
};
