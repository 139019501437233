import React from "react";
import { useAppContext } from "../context/AppContext";
import { Routes, Route } from "react-router-dom";

const Router = () => {
  const { routes } = useAppContext();
  return routes ? (
    <Routes>
      {routes.map((route, i) => (
        <Route
          key={`route_${i}`}
          path={route.path}
          element={<route.component route={route} />}
        />
      ))}
    </Routes>
  ) : null;
};

export default React.memo(Router);
