import { createAction } from 'redux-act';
import {IMyPhoneNumbers, ITopBarNavigation, IUser} from "../reducers";

export const signInUser = createAction<{ email: string; password: string }>('to signin');
export const signOutUser = createAction('to sign out');
export const authUser = createAction('authorize user');
export const setSignupMessage = createAction<{ isError: boolean; message: string ; errors?: object} | undefined>('set signup response');
export const setUserData = createAction<IUser>('set user response');
export const updatePreDraftMessage = createAction<string>('update user predraft message');
export const setRoutes = createAction<ITopBarNavigation[]>('update routes');
export const setComponentRoutes = createAction<string[]>('set component routes');
export const fetchProfile = createAction('fetch profile details');
export const forgotPassword = createAction<{email: string;}>();
export const resetPassword = createAction<{newPassword: string;code: string;navigation: any;}>();
export const verifyMyAccount = createAction<{code: string;navigation: any;}>();
export const updateProfile = createAction<{ firstName: string; lastName: string; centerName: string; website: string; location: string}>();
export const updateMyPhoneNumbers = createAction<IMyPhoneNumbers[]>('update user my phone numbers');
export const selectPhoneNumber = createAction<number | undefined>('select my phone number');
export const fetchAllPhoneNumbersDetails = createAction<{ id: number }>(
    "fetch all phone numbers"
);