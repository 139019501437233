import { IUser } from "./user";
import {
  changeUsersCurrentPage,
  changeUsersPerPage,
  fetchUsers,
  resetUsersDetails,
  setAdhocCharges,
  setAllPlans, setAllUserBills, setAllUserBillsCount, setAllUserBillsCurrentPage, setAllUserBillsPerPage,
  setCredits,
  setLastInvoice,
  setPrefetchedCardData,
  setSelectedUser,
  setSelectedUserBill,
  setSingleAdhocChargeData,
  setTotalAddressRecords, setTotalSubUserRecords,
  setTotalUsersRecords,
  setUsers,
  signOutUser, toggleAdhocChargeModal,
  toggleChangeExpiryDateModal,
  togglePlanchooserModal,
  toggleUserLoader,
  updateAdhocChargeData,
  updateHostedNumberData
} from "../actions";
import { createReducer } from "redux-act";

export interface IUsersBill {
  id: number;
  amount: number;
  type_of_transaction: string;
  transaction_description: string;
  transaction_id: string;
  start_date: string;
  end_date: string;
  is_subscription: boolean;
  status: string;
  plan_id: number;
  invoice?: any;
  plan?: any
}
export interface IUsersAdhocCharge {
  id: number;
  amount: number;
  charge_type: string;
  remaining_amount: number;
  is_paid: boolean;
  invoice_id: string;
  description: string;
}
interface IPlan {
  features: string[];
  id: number;
  image_location: string;
  is_popular: boolean;
  is_current_plan: boolean;
  is_free_trial: boolean;
  expiry_date: string | null;
  start_date: string | null;
  change_allowed: boolean;
  plan_name: string;
  description: string;
  plan_price: string;
  status: string;
}
interface IInvoice {
  invoice: any;
  last_transaction: {
    amount: number;
    type_of_transaction: string;
    transaction_description: string;
    transaction_id: string;
    start_date: string;
    end_date: string;
    status: string;
    is_subscription: boolean;
    created_by: number;
  };
}
export interface IHostedNumber {
  name?: string;
  street?: string;
  region?: string;
  phone_number?: string;
  is_tollfree?: boolean;
  city?: string;
  country?: string;
  line_type_intelligence?: string;
  status?: string;
  postal_code?: string;
}

export interface IUsersReducer {
  users: IUser[];
  selectedUserData: {
    userData?: IUser;
    hostedNumber: IHostedNumber | null;
    selectedUserBills: IUsersBill[];
    newAdhocCharge: any;
    adhocCharges: IUsersAdhocCharge[];
    cardDetails?: any;
    allPlans: IPlan[];
    credits: number;
    lastInvoice?: IInvoice;
  };
  allBills: IUsersBill[];
  allBillsCount: number;
  allBillsPerPage: number;
  allBillsCurrentPage: number;
  showPlanChooserModal: boolean;
  showChangeExpiryModal: boolean;
  showAdhocChargeModal: boolean;
  totalRecords: number;
  totalAddressRecords: number;
  totalSubUserRecords: number;
  perPage: number;
  currentPage: number;
  isLoading: boolean;
}

const initialState = {
  users: [],
  selectedUserData: {
    selectedUserBills: [],
    adhocCharges: [],
    newAdhocCharge: null,
    hostedNumber: null,
    credits: 0,
    allPlans: [],
  },
  allBills: [],
  allBillsCount: 0,
  allBillsPerPage: 10,
  allBillsCurrentPage: 1,
  showAdhocChargeModal: false,
  showPlanChooserModal: false,
  showChangeExpiryModal: false,
  totalRecords: 0,
  totalAddressRecords: 0,
  totalSubUserRecords: 0,
  perPage: 10,
  currentPage: 0,
  isLoading: false,
};
export const users = createReducer<IUsersReducer>({}, initialState);
users.on(fetchUsers, (state: IUsersReducer) => ({
  ...state,
  isLoading: true,
}));

users.on(setUsers, (state: IUsersReducer, payload: IUser[]) => ({
  ...state,
  users: payload,
  isLoading: false,
}));
users.on(toggleUserLoader, (state: IUsersReducer, payload: boolean) => ({
  ...state,
  isLoading: payload,
}));
users.on(changeUsersCurrentPage, (state: IUsersReducer, payload: number) => ({
  ...state,
  currentPage: payload,
}));
users.on(changeUsersPerPage, (state: IUsersReducer, payload: number) => ({
  ...state,
  perPage: payload,
}));

users.on(setTotalUsersRecords, (state: IUsersReducer, payload: number) => ({
  ...state,
  totalRecords: payload,
}));
users.on(setTotalAddressRecords, (state: IUsersReducer, payload: number) => ({
  ...state,
  totalAddressRecords: payload,
}));

users.on(setTotalSubUserRecords, (state: IUsersReducer, payload: number) => ({
  ...state,
  totalSubUserRecords: payload,
}));

users.on(
  setSelectedUser,
  (state: IUsersReducer, payload: IUser | undefined) => ({
    ...state,
    selectedUserData: {
      ...state.selectedUserData,
      userData: payload,
    },
  })
);

users.on(togglePlanchooserModal, (state: IUsersReducer, payload: boolean) => ({
  ...state,
  showPlanChooserModal: payload,
}));

users.on(
  setSelectedUserBill,
  (state: IUsersReducer, payload: IUsersBill[]) => ({
    ...state,
    selectedUserData: {
      ...state.selectedUserData,
      selectedUserBills: payload,
    },
  })
);
users.on(setPrefetchedCardData, (state: IUsersReducer, payload: any) => ({
  ...state,
  selectedUserData: {
    ...state.selectedUserData,
    cardDetails: payload,
  },
}));
users.on(setLastInvoice, (state: IUsersReducer, payload: any) => ({
  ...state,
  selectedUserData: {
    ...state.selectedUserData,
    lastInvoice: payload,
  },
}));

users.on(setAllPlans, (state: IUsersReducer, payload: any) => ({
  ...state,
  selectedUserData: {
    ...state.selectedUserData,
    allPlans: payload,
  },
}));
users.on(setCredits, (state: IUsersReducer, payload: number) => ({
  ...state,
  selectedUserData: {
    ...state.selectedUserData,
    credits: payload,
  },
}));

users.on(resetUsersDetails, (state: IUsersReducer) => ({
  ...state,
  selectedUserData: initialState.selectedUserData,
}));
users.on(
  toggleChangeExpiryDateModal,
  (state: IUsersReducer, payload: boolean) => ({
    ...state,
    showChangeExpiryModal: payload,
  })
);
users.on(updateHostedNumberData, (state: IUsersReducer, payload: any) => ({
  ...state,
  selectedUserData: {
    ...state.selectedUserData,
    hostedNumber: payload,
  },
}));

users.on(setAdhocCharges, (state: IUsersReducer, payload: any) => ({
  ...state,
  selectedUserData: {
    ...state.selectedUserData,
    adhocCharges: payload,
  },
}));

users.on(setSingleAdhocChargeData, (state: IUsersReducer, payload: any) => ({
  ...state,
  selectedUserData: {
    ...state.selectedUserData,
    newAdhocCharge: payload,
  },
}));
users.on(updateAdhocChargeData, (state: IUsersReducer, payload: any) => ({
  ...state,
  selectedUserData: {
    ...state.selectedUserData,
    newAdhocCharge: {
      ...state.selectedUserData.newAdhocCharge,
      [payload.key]: payload.value,
    },
  },
}));

users.on(toggleAdhocChargeModal, (state: IUsersReducer, payload: boolean) => ({
  ...state,
  showAdhocChargeModal: payload
}));
users.on(setAllUserBills, (state: IUsersReducer, payload: IUsersBill[]) => ({
  ...state,
  allBills: payload
}));
users.on(setAllUserBillsCount, (state: IUsersReducer, payload: number) => ({
  ...state,
  allBillsCount: payload
}));
users.on(setAllUserBillsPerPage, (state: IUsersReducer, payload: number) => ({
  ...state,
  allBillsPerPage: payload
}));
users.on(setAllUserBillsCurrentPage, (state: IUsersReducer, payload: number) => ({
  ...state,
  allBillsCurrentPage: payload
}));

users.on(signOutUser, () => initialState);
